import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tooltip,
  Center,
  Spinner,
  Link,
} from '@chakra-ui/react';
import moment from 'moment';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Hardware } from '../../apiClient/types';
import { ActionStatusBadge } from '../../hardware/ActionStatusBadge';
import { useLoadSingleHardware } from './useLoadSingleHardware';

type Props = {
  hardware: Hardware;
};

export function HardwareActionHistoryContent({ hardware }: Props) {
  const { hardwareLocation } = useParams();
  if (!hardwareLocation) {
    throw new Error('Component can only be used as a route element with the required params.');
  }

  const { isLoading, loadHardware, hardwareDetail } = useLoadSingleHardware(hardwareLocation);

  useEffect(() => {
    loadHardware();
  }, [loadHardware]);

  const hardwareData =
    hardwareDetail?.actionRuns?.map((action) => ({
      id: action.id,
      user: action.createdBy,
      action: action.action.name,
      timestamp: moment(action.createdOn).format('DD/MM/YYYY HH:mm'),
      actionRun: action,
      option: action.option?.name,
    })) || [];

  if (isLoading) {
    return (
      <Center h="100px">
        <Spinner />
      </Center>
    );
  }

  return (
    <Box borderRadius="md" border="1px" borderColor="gray.200" maxHeight="500px" overflowX="hidden">
      <Table size="sm">
        <Thead>
          <Tr>
            <Th>
              <FormattedMessage id="hardwaredetailpage_action_history_id" />
            </Th>
            <Th>
              <FormattedMessage id="hardwaredetailpage_action_history_user" />
            </Th>
            <Th>
              <FormattedMessage id="hardwaredetailpage_action_history_action" />
            </Th>
            <Th>
              <FormattedMessage id="hardwaredetailpage_actions_option" />
            </Th>
            <Tooltip label="DD/MM/YYYY">
              <Th>
                <FormattedMessage id="hardwaredetailpage_action_history_timestamp" />
              </Th>
            </Tooltip>
            <Th>
              <FormattedMessage id="hardwaredetailpage_actions_result" />
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {hardwareData.map((data, index) => (
            <Tr key={index}>
              <Td fontWeight="bold" textColor={'blue.300'}>
                <Link
                  as={NavLink}
                  to={`/hardware/${hardware.serialNumber}&${hardware.server.identifier}/run/${data.id}`}
                >
                  {data.id}
                </Link>
              </Td>
              <Td>{data.user}</Td>
              <Td>{data.action}</Td>
              <Td>{data.option || ''}</Td>
              <Td>{data.timestamp}</Td>
              <Td>
                <ActionStatusBadge run={data.actionRun} />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
}
