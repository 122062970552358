import React from 'react';
import { Badge, Divider, Flex, Spinner, Text, Tooltip } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { useLoadGroups } from './useLoadGroups';

export function UserGroupBadge() {
  const { isLoading, realms } = useLoadGroups();

  if (isLoading) {
    return (
      <Flex flexDir="row" gap={2} flexWrap="wrap" maxW="220px" overflow="hidden">
        <Spinner size="sm" mb="2.5px" />
      </Flex>
    );
  }

  if (!realms) {
    return (
      <Flex
        flexDir="row"
        gap={2}
        flexWrap="wrap"
        maxW="220px"
        overflow="hidden"
        alignItems="center"
      >
        <Badge colorScheme="red">
          <FormattedMessage id="useshowerror_error" />
        </Badge>
      </Flex>
    );
  }

  const realmsWithActiveGroups = realms.filter((realm) =>
    realm.groups.some((group) => group.active)
  );

  return (
    <Flex flexDir="column" gap={3} w="full" overflow="hidden">
      {realmsWithActiveGroups.map((realm, index) => (
        <React.Fragment key={realm.id}>
          <Flex flexDir="column" w="full">
            <Tooltip label={realm.description}>
              <Text fontSize="sm" fontWeight="bold">
                {realm.name}
              </Text>
            </Tooltip>
            <Flex flexDir="row" flexWrap="wrap" gap={2} pt="1.5">
              {realm.groups.map(
                (group) =>
                  group.active && (
                    <Tooltip label={group.description} key={group.id}>
                      <Badge textTransform="none" maxW="fit-content" colorScheme="gray">
                        {group.name}
                      </Badge>
                    </Tooltip>
                  )
              )}
            </Flex>
            {index < realmsWithActiveGroups.length - 1 && <Divider pt="3" w="100%" />}
          </Flex>
        </React.Fragment>
      ))}
    </Flex>
  );
}
