import { useQuery } from '@tanstack/react-query';

export function useRefreshHardware() {
  const useRefreshHardwareQuery = (onRequestHardwareRefresh: () => void) => {
    useQuery({
      queryKey: ['refreshHardware'],
      queryFn: () => onRequestHardwareRefresh(),
      refetchOnWindowFocus: false,
    });
  };

  return { useRefreshHardwareQuery };
}
