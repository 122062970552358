import { Box, Table, Tbody, Td, Text, Th, Thead, Tooltip, Tr } from '@chakra-ui/react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { ActionResult } from '../../apiClient/types';
import { ActionStatusBadge } from '../../hardware/ActionStatusBadge';

type Props = {
  actionResult: ActionResult;
};

export function ActionResultAttributesTable({ actionResult }: Props) {
  return (
    <Box w="full">
      <Text fontWeight="bold" pl={1} pt={1} pb={3}>
        <FormattedMessage id="hardwareactionlogpage_tab_summary" />
      </Text>
      <Box overflowX="auto">
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>
                <FormattedMessage id="hardwareactionlogpage_actionname" />
              </Th>
              <Th>
                <FormattedMessage id="hardwareactionlogpage_actionid" />
              </Th>
              <Th>
                <FormattedMessage id="hardwareactionlogpage_runstatus" />
              </Th>
              <Th>
                <FormattedMessage id="hardwareactionlogpage_runid" />
              </Th>
              <Th>
                <FormattedMessage id="hardwareactionlogpage_startedby" />
              </Th>
              <Th>
                <Tooltip label="DD/MM/YYYY">
                  <FormattedMessage id="hardwareactionlogpage_starttime" />
                </Tooltip>
              </Th>
              <Th>
                <Tooltip label="DD/MM/YYYY">
                  <FormattedMessage id="hardwareactionlogpage_completedtime" />
                </Tooltip>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>{actionResult.run.action.name}</Td>
              <Td>{actionResult.run.action.id}</Td>
              <Td>
                <ActionStatusBadge run={actionResult.run} />
              </Td>
              <Td>{actionResult.run.id}</Td>
              <Td>{actionResult.run.createdBy}</Td>
              <Td>{moment(actionResult.run.createdOn).format('DD/MM/YYYY HH:mm')}</Td>
              <Td>{moment(actionResult.completedOn).format('DD/MM/YYYY HH:mm')}</Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
}
