import { useIntl } from 'react-intl';
import { Hardware } from '../../apiClient/types';
import { HardwareActionHistoryContent } from './HardwareActionHistoryContent';
import { HardwareDetailModal } from './HardwareDetailModal';

type Props = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  hardware: Hardware;
};

export const ActionHistoryModal = ({ isOpen, onOpen, onClose, hardware }: Props) => {
  const intl = useIntl();

  return (
    <HardwareDetailModal
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      title={[
        hardware.name,
        intl.formatMessage({ id: 'hardwaredetailpage_action_history_modal_title' }),
      ].join(' - ')}
      size="3xl"
      h="300px"
      minH="300px"
    >
      <HardwareActionHistoryContent hardware={hardware} />
    </HardwareDetailModal>
  );
};
