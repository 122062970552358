import { useMsal, useAccount } from '@azure/msal-react';
import {
  Box,
  Button,
  Heading,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { ChevronDownIcon, EditIcon, Icon } from '@chakra-ui/icons';
import { BiUserCircle } from 'react-icons/bi';
import { tz } from 'moment-timezone';
import { ReactElement, ReactNode, useState } from 'react';
import { UserGroupModal } from '../../pages/groups/UserGroupModal';
import { UserGroupBadge } from '../../pages/groups/UserGroupBadge';
import { useIsMobile } from '../../useIsMobile';

function MenuInfoItem(props: {
  title: ReactNode;
  children: ReactNode;
  hasIcon?: boolean;
  icon?: ReactElement;
  iconLabel?: string;
  onClick?: () => void;
  shouldOpenModal?: boolean;
}) {
  return (
    <Stack py={2} px="12.8px" color="gray.500">
      {props.hasIcon && props.icon && props.iconLabel ? (
        <Button
          rightIcon={props.icon}
          aria-label={props.iconLabel}
          onClick={props.shouldOpenModal ? props.onClick : undefined}
          bgColor="transparent"
          color="gray.500"
          size="xs"
          maxW="fit-content"
          pl="0"
          _hover={{ bgColor: 'transparent', color: 'gray.600' }}
          _active={{ bgColor: 'transparent', color: 'black' }}
          _selected={{ bgColor: 'transparent', color: 'black' }}
        >
          <Heading size="xs">{props.title}</Heading>
        </Button>
      ) : (
        <Heading size="xs">{props.title}</Heading>
      )}
      {props.children}
    </Stack>
  );
}

export function LoggedInUserMenu() {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  const isMobile = useIsMobile();
  const intl = useIntl();

  return (
    <Box>
      <Menu
        closeOnBlur={isOpen ? false : true}
        autoSelect={false}
        placement={isMobile ? 'bottom-start' : 'bottom-end'}
      >
        <MenuButton color="white" display="block">
          <HStack spacing={2}>
            <Icon as={BiUserCircle} boxSize={8} />
            <ChevronDownIcon fontSize="lg" boxSize="1em" />
          </HStack>
        </MenuButton>
        <MenuList w="230px">
          <MenuInfoItem title={<FormattedMessage id="header_logged_in_as" />}>
            <HStack alignItems="start">
              <Icon as={BiUserCircle} boxSize="24px" />
              <Box>
                <Text fontWeight="bold">{account?.name}</Text>
                <Tooltip label={account?.username} placement="bottom-end">
                  <Text maxW="170px" noOfLines={1}>
                    {account?.username}
                  </Text>
                </Tooltip>
              </Box>
            </HStack>
          </MenuInfoItem>
          <MenuInfoItem title={<FormattedMessage id="header_timezone" />}>
            <Text>{tz.guess()}</Text>
          </MenuInfoItem>
          <MenuInfoItem
            title={<FormattedMessage id="header_groups" />}
            hasIcon
            icon={<EditIcon mb="1.5px" />}
            iconLabel={intl.formatMessage({ id: 'header_edit_groups' })}
            shouldOpenModal
            onClick={handleOpen}
          >
            <UserGroupBadge />
          </MenuInfoItem>
          <MenuDivider />
          <MenuItem>
            <RouterNavLink style={{ display: 'block', width: '100%' }} to="/logout">
              <FormattedMessage id="header_logout" />
            </RouterNavLink>
          </MenuItem>
        </MenuList>
        <UserGroupModal isOpen={isOpen} onClose={handleClose} />
      </Menu>
    </Box>
  );
}
